.SpotForm {
  form {
    background-color: transparent;
  }

  .name,
  .from,
  .slug,
  .country {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .submit {
    display: none;
  }
}
