@import 'styles/vars';

.Tips {
  padding: $margin 0;

  h3,
  button {
    margin-top: 16px;
  }

  input[type='text'],
  textarea {
    width: 100%;
  }

  textarea {
    height: 150px;
  }
}
