@import 'styles/vars';

.Login {
  .left {
    padding: $margin;
    width: 50%;
    float: left;

    @media (max-width: 800px) {
      display: none;
    }

    h1 {
      margin: 0 0 ($margin * 1.6);
      text-align: center;
    }

    .video {
      margin: 0 auto;
      text-align: center;
    }
  }

  .right {
    width: 50%;
    float: left;
    padding: $margin;

    @media (max-width: 800px) {
      width: 100%;
    }

    .form {
      width: 100%;
      float: right;
      padding: $margin;

      h2 {
        text-align: center;
      }

      input {
        width: 100%;
        margin-top: $margin;
      }

      .Button {
        display: block;
        width: 100%;
        margin: $margin auto 0;
      }

      .Error {
        margin: 10px 0 0;
      }
    }
  }
}
