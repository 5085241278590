@import 'styles/vars';

// TODO: width: 100% and margin: 5px 0 should be here, maybe.
input[type='text'],
input[type='email'],
input[type='password'],
select,
textarea {
  background: $color-primary;
  border: 1px solid $color-primary-hover;
  color: $color-primary-contrast;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  height: 40px;
  display: inline-block;
  border-radius: 0;

  &::placeholder {
    color: $color-secondary-contrast;
  }
}

button,
.button {
  display: inline-block;
  line-height: 38px;
  border: 1px solid $color-primary-hover;
  padding: 0 23px;
  background-color: $color-primary;
  color: $color-primary-contrast;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  border-radius: 0;

  &:hover,
  &:focus {
    background-color: $color-primary-hover;
    text-decoration: underline;
  }

  &:focus {
    outline: 3px solid #fff;
  }

  &.secondary {
    background-color: $color-secondary;

    &:hover,
    &:focus {
      background-color: $color-secondary-hover;
    }
  }

  &[disabled] {
    background-color: $color-disabled !important;

    &:hover,
    &:focus {
      background-color: $color-disabled-hover !important;
    }
  }
}

hr,
.hr {
  border: none;
  border-bottom: 1px solid $color-primary;
}

h1,
.h1 {
  line-height: normal;
  font-size: 40px;
  font-weight: 300;
  color: $color-primary;
}

h2,
.h2 {
  line-height: normal;
  font-size: 30px;
  font-weight: 300;
  color: $color-primary;

  &.light {
    font-weight: 300;
  }
}

h3,
.h3 {
  line-height: normal;
  font-size: 25px;
  font-weight: 300;
  color: $color-primary;

  &.light {
    font-weight: 300;
  }
}

h4,
.h4 {
  line-height: normal;
  font-size: 20px;
  font-weight: 300;
  color: $color-primary;

  &.light {
    font-weight: 300;
  }
}

.caption {
  line-height: normal;
  font-size: 10px;
  font-weight: 300;
  color: $color-secondary;
}

.flex {
  display: flex;

  &.flex-center {
    justify-content: center;
    align-items: center;
  }
}

table,
.table {
  font-size: 14px;
  width: 100%;

  tr {
    border-bottom: 1px solid $color-secondary;

    &:hover {
      td {
        background-color: #111;
      }
    }

    th {
      background-color: #222;
    }

    th,
    td {
      line-height: 40px;
      padding: 0 $margin / 2;
      border-left: 1px solid $color-secondary;
      text-align: center;

      &:last-of-type {
        border-right: 1px solid $color-secondary;
      }

      &.table-number {
        text-align: right;
      }

      &.table-text,
      &.table-string {
        text-align: left;
      }
    }
  }
}

button {
  &.reject {
    background-color: #dc1c36aa;
    color: #fff;
    border: none;
    font-weight: 300;
  }

  &.approve {
    background-color: #00ff00aa;
    color: #fff;
    border: none;
    font-weight: 300;
  }
}

a.link {
  color: $color-primary;
  font-weight: 700;
}

form,
.form {
  background: $color-primary-contrast;
}
