@import 'styles/vars';

.Seeds {
  padding: $margin 0;

  .newSeed {
    margin-bottom: $margin;

    input {
      width: 70%;
    }

    .Button {
      width: 30%;
    }
  }

  .left {
    float: left;
    width: 30%;
    padding-right: 10px;

    @media (max-width: 600px) {
      width: 100%;
      padding-right: 0;
    }

    .time {
      @media (max-width: 800px) {
        display: none;
      }
    }
  }

  .right {
    float: left;
    width: 70%;
    padding-left: 10px;

    @media (max-width: 600px) {
      width: 100%;
      padding-left: 0;
    }

    > div {
      float: left;
      width: 25%;

      @media (max-width: 1000px) {
        width: 33%;
      }

      @media (max-width: 500px) {
        width: 50%;
        padding-left: 0;
      }
    }
  }
}
