@import 'styles/vars';

.Marketing {
  textarea {
    display: block;
    width: 100%;
    height: 300px;
    font-size: 12px;
    line-height: 14px;
  }

  .user {
    line-height: 30px;

    span {
      cursor: pointer;
    }
  }
}
