@import 'styles/vars';

$dataHeight: 15px;

.Curator {
  height: calc(100vh - #{$headerHeight} - #{$dataHeight});

  .data {
    line-height: 15px;
    text-align: center;
  }

  .current {
    position: relative;
    height: 100%;

    > img {
      max-width: 100%;
      max-height: calc(100% - 40px); // buttons
      display: block;
      margin: 0 auto;

      &.full-screen {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100vw;
        max-height: 100vh;
        margin: 0;
      }
    }

    &.--with-no-buttons {
      > img {
        max-height: 100%; // remove button's height
      }
    }

    h1 {
      text-align: center;
    }

    .buttons {
      overflow: hidden;
      position: absolute;
      bottom: 0;
      width: 100%;

      > * {
        width: 50%;
      }
    }
  }
}
