@import 'styles/vars';

.Searcher {
  padding: $margin 0;

  &__filter {
    display: flex;
    align-items: center;

    &-name {
      width: 100px;
    }

    &-value {
      width: 300px;

      input {
        width: 100%;
      }
    }

    &-bool {
      width: 100px;
    }
  }

  &__table {
    font-family: monospace;
    font-size: 12px;
    line-height: 20px;
  }

  &__row {
    display: flex;

    &:nth-of-type(2n) {
      background-color: #222;
    }
  }

  &__col {
    &.clicked {
      background-color: #555;
    }

    &.has-action {
      cursor: pointer;
    }

    &:nth-of-type(1) {
      width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 4px;
    }

    &:nth-of-type(2) {
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 4px;
    }

    &:nth-of-type(3) {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 4px;
    }

    &:nth-of-type(4) {
      width: 100px;
    }

    &:nth-of-type(5) {
      width: 50px;
    }

    &:nth-of-type(6) {
      width: 120px;
    }

    &:nth-of-type(7) {
      width: 40px;
    }
  }
}
