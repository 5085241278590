@import 'styles/vars';

.Notifications {
  input[type='text'],
  textarea {
    margin-top: 8px;
    width: 100%;
  }

  input[type='checkbox'] {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin: 20px 0;

    & + span {
      line-height: 30px;
      margin-left: 5px;
    }
  }

  textarea {
    height: 100px;
  }

  .Button {
    margin-top: 10px;
  }
}
