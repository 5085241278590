$width: 8px;
$height: 8px;

.CrosshairPoint {
  width: $width;
  height: $height;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transform: translate(-50%, -50%);
  background-color: #FFF;
}
