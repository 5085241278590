@import 'styles/vars';

.Collector {
  padding: $margin 0;
  height: calc(100vh - #{$headerHeight});

  .remainingLimit {
    text-align: right;
    padding: 0 $margin;

    > .limit {
      font-weight: bold;
      transition: 1s all;

      &.--warning {
        vertical-align: bottom;
        font-size: 50px;
        color: red;
      }
    }

    > .when {
      font-style: italic;
    }
  }

  .console {
    margin-top: $margin;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .table {
    .cta {
      &.--warning {
        color: red;
      }
    }

    tr {
      > td {
        > img {
          display: block;
          max-width: 500px;
          max-height: 500px;
        }
      }
    }
  }
}
