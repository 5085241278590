.MainPhotoForm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 2;

  img {
    border: 1px solid #fff;
    min-width: 50px;
    min-height: 50px;
    max-height: 300px;
    cursor: pointer;
  }
}
