@import 'styles/vars';

.Moderation {
  height: calc(100vh - #{$headerHeight});

  $dataHeight: 180px;

  .LoadingPage {
    position: fixed;
    top: $margin / 2;
    left: $margin / 2;
    right: unset;
    bottom: unset;
    background-color: red;
    color: #000;
  }

  > .left {
    width: 50%;
    height: 100%;
    float: left;
    text-align: center;

    > .photo {
      display: inline-block;
      max-width: 100%;
      max-height: 90%;
    }

    > .photo-description {
      height: 10%;
    }

    > .slider {
      height: 100%;

      > .quantity {
        line-height: 30px;
      }

      > .Slider {
        height: calc(100% - 30px);
      }
    }

    > .reject-form {
      > textarea {
        display: block;
        width: 100%;
        height: 100px;
      }

      > button {
        width: 100%;
      }
    }

    .assign_photo {
      width: 45%;
      margin-left: 2%;
    }
  }

  > .right {
    width: 50%;
    height: 100%;
    float: left;

    > .filters {
      line-height: 30px;
      font-size: 12px;

      > input {
        margin-left: 20px;

        &:first-of-type {
          margin-left: 0;
        }

        &[type='text'] {
          width: 40px;
          height: 20px;
          line-height: 18px;
          font-size: 10px;
          padding: 0;
          border: 0;

          &.coor {
            width: 25px;
          }
        }
      }

      > span {
        cursor: pointer;
      }
    }

    > .map {
      width: 100%;
      height: calc(100% - 30px);
    }
  }
}
