$width: 10px;
$height: 10px;

.ColorPoint {
  width: $width;
  height: $height;
  background-color: red;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  transform: translate(-50%, -50%);

  &.--native {
    border: 1px solid #ffda00;
  }

  &.--active {
    box-shadow: 0 0 0 1px rgba(red, 0.8);
  }

  &.--with-data {
    background-color: green !important;
  }
}
