@import 'styles/vars';

.Map {
  position: relative;
  width: 100%;
  height: 100%;

  > .actions {
    position: absolute;
    left: $margin / 2;
    top: $margin / 2;
    z-index: 1;

    > button {
      margin-right: $margin / 2;
    }
  }

  > .count {
    position: absolute;
    bottom: 5px;
    left: 80px;
    color: #FFF;
    font-weight: 500;
  }
}
