@import 'styles/vars';

.Areas {
  height: calc(100vh - #{$headerHeight});
  display: flex;

  .left {
    flex: 1;
    overflow-y: auto;

    input[type='text'] {
      width: 100%;

      &.--active {
        border-color: red;
      }
    }
  }

  .right {
    flex: 1;
  }

  &__map {
    height: 100%;
  }

  &__block {
    margin-top: 32px;
  }

  &__block-name {
    display: flex;

    > div {
      flex: 1;
    }
  }
}
