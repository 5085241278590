@import 'styles/vars';

.Header {
  overflow: auto;
  height: $headerHeight;
  background-color: #111;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 450px) {
    font-size: 12px;
  }

  .hello {
    @media (max-width: 650px) {
      display: none;
    }
  }

  .menu {
    float: right;
    line-height: $headerHeight;

    .item {
      margin-left: $margin / 2;

      &:first-of-type {
        margin-left: 0;
      }

      &.--active {
        text-decoration: underline;
      }
    }
  }
}
