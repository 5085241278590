$color-primary: #DDD;
$color-primary-hover: #CCC;
$color-primary-contrast: #222;

$color-secondary: #BBB;
$color-secondary-hover: #AAA;
$color-secondary-contrast: #444;

$color-disabled: #999;
$color-disabled-hover: #888;

$margin: 30px;

/* Vars */
$headerHeight: 60px;
