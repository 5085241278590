@import 'styles/vars';

.Translation {
  textarea {
    width: 100%;
    height: 100px;
  }

  .Button {
    margin-top: 10px;
  }
}
