$width: 8px;
$height: 8px;

.SpotPoint {
  width: $width;
  height: $height;
  background-color: orange;
  opacity: 0.8;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transform: translate(-50%, -50%);

  &.--active {
    border: 1px solid red;
  }
}
