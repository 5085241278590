.DescriptionForm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 2;

  input {
    width: 100%;
  }

  img {
    border: 1px solid #fff;
    min-width: 50px;
    min-height: 50px;
    max-height: 400px;
  }

  &__from {
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0;
    cursor: pointer;
  }
}
