@import 'styles/vars';

.Slider {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  > .arrow {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
    background-color: #FFF;
    border-radius: 50%;
    background-image: url(./imgs/arrow-left.ico);
    background-size: 100% 100%;
    cursor: pointer;

    &.arrow-left {
      left: 20px;
    }

    &.arrow-right {
      right: 20px;
      transform: rotate(180deg);
    }
  }

  > img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}
