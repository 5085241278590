@import 'styles/fonts';
@import 'styles/vars';
@import 'styles/reset';

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #CCC;
  background-color: #000;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

a,
a:active,
a:visited {
  color: inherit;
  text-decoration: inherit;
}

.content {
  width: 90vw;
  margin: 0 auto;
}

.cta {
  cursor: pointer;
}

@import 'styles/ui.scss';
